body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #0099ff;
}

button {
  outline: none;
  border: none;
}

.utogi-feature {
  flex: 0 0 60%;
  & > .tabs {
    .nav-tabs .nav-item .nav-link {
      border: none;
      border-radius: 10px !important;
      box-shadow: 0 0 10px #00000016;
      margin-right: 15px;
      color: #00aeef;
      &.active {
        background-color: #00aeef;
        color: #ffffff;
      }
    }
    &.default.small > .tab-header {
      border-bottom: none;
      & > .tab-item {
        border-radius: 5px;
        padding: 10px 15px;
        margin-right: 15px;
        color: #00aeef;
        &.active {
          color: #ffffff;
        }
        @media screen and (max-width: 340px) {
          width: 100%;
        }
      }
    }
    .tab-content {
      .tab-pane {
        border: none;
        padding: 20px 0;
      }
    }
  }
  &-list {
    @media screen and (min-width: 425px) {
      display: flex;
      flex-wrap: wrap;
    }
    margin: 0 -20px;
    &-item {
      flex: 0 0 50%;
      padding: 0px 20px 40px 20px;
      .intro {
        display: block;
        color: #666666;
        font-size: 13px;
        font-weight: 700;
        display: block;
      }
      .heading {
        font-size: 22px;
        margin: 10px 0;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
          max-width: 40px;
        }
      }
      p {
        color: #808080;
        font-size: 14px;
        line-height: 2;
      }
      a {
        display: flex;
        align-content: center;
        align-items: center;
        i {
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }
  }
}
.background-image {
  background-image: url("./assets/images/page-background.svg");
}
section {
  .section-heading,
  .section-sub-heading {
    font-size: 15px;
    display: block;
    text-align: center;
    line-height: 1.5;
  }
  .section-heading {
    font-size: 28px;
    margin: 10px 0 40px;
  }
}
.btn-default {
  padding: 15px 30px;
  background: linear-gradient(to left, #00aeef, #0099ff);
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 3px;
  font-size: 13px;
  box-shadow: 0 5px 10px #00000020;
}

.animated-icon {
  background-color: #ffffff;
  border-radius: 50%;
  color: #00aeef;
  padding: 9px;
  font-size: 16px;
  line-height: 15px;
  position: absolute;
  box-shadow: 0 0 15px #00000050;
  animation: fade-in-fade-out 8s linear infinite alternate;
  z-index: 100;
}

.client-list {
  list-style: none;
  padding: 100px 0;
  text-align: center;
  li {
    display: inline-block;
    img {
      max-height: 80px;
      padding: 0 50px;
    }
  }
}

@keyframes fade-in-fade-out {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
}

@keyframes roll-out {
  0% {
    top: 0%;
  }
  100% {
    top: -150%;
  }
}

.page {
  header {
    background-image: url("./assets/images/page-header-bg.jpg");
    background-size: cover;
    background-position: bottom;
    position: relative;
    .header-block {
      padding: 150px 0;
      .heading,
      .sub-heading {
        font-size: 15px;
        display: block;
        text-align: center;
        color: #fff;
      }
      .heading {
        font-size: 28px;
        margin: 10px 0 40px;
      }
    }
  }
  .select-user-type-list {
    display: flex;
    margin-top: -100px;
    align-self: stretch;
    position: relative;
    z-index: 100;
    &-item {
      flex: 0 0 25%;
      padding: 0 20px;
      cursor: pointer;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      & > div {
        box-shadow: 0 0 54px #00000018;
        background-color: #ffffff;
        padding: 80px 50px;
        font-size: 22px;
        height: 100%;
      }
      &.selected {
        & > div {
          background-color: #009ffa;
          color: #ffffff;
        }
      }
    }
  }
}

.container {
  margin: 0 20px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1380px;
  }
}
