.navigation-menu {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0 0 0 20px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  &-left {
    display: flex;
    nav {
      padding-left: 100px;
    }
  }
  .logo {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 18px;
    img {
      display: block;
      max-width: 100px;
    }
  }
  .cta-button {
    color: #ffffff;
    background-color: #666666;
    padding: 15px 20px;
    border-radius: 50px;
  }
  nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 16px;
      display: flex;
      li {
        font-size: 15px;
        border: 1px solid #ffffff;
        margin-left: 20px;
        &.login {
          background-color: #ffffff;
          a {
            color: #0099ff;
          }
        }
      }
    }
  }
}
