.contact-us {
  header .header-block {
    padding: 150px 0 50px;
  }
  .utogi-feature-section {
    padding: 200px 0 100px;
    .contact-form {
      .form-input {
        margin-bottom: 25px;
      }
      button {
        background-color: #f9a215;
        color: #fff;
        padding: 10px 20px;
        outline: none;
        border: none;
        border-radius: 5px;
        box-shadow: 5px 5px 5px #00000016;
      }
    }
    .alert {
      margin-bottom: 15px;
    }
  }
}
