.form-input {
  position: relative;
  align-items: center;
  i {
    .input-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
  textarea {
    padding: 10px;
    width: 100%;
    box-shadow: none;
    border: none;
    background: none;
    box-shadow: 0 4px 11px #00000013;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 13px;
    line-height: 1;
  }
  &.has-errors {
    textarea {
      border-color: #cc6363 !important;
      box-shadow: 0 0 2px 1px #ffa3a3;
    }
  }
  .form-errors {
    list-style: none;
    text-align: left;
    font-size: 12px;
    padding: 10px 0 0;
    color: #cc6363;
  }
}
