@import "~styles/colors";

.loader {
  min-height: 500px;
  width: 100%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  .lds-ring {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    width: 64px;
    height: 64px;
    margin: 0 auto;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 51px;
      height: 51px;
      margin: 6px;
      border: 6px solid $color1;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $color1 transparent transparent transparent;
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
